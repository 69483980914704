var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-tabs",
    {
      attrs: { type: "card" },
      model: {
        value: _vm.activeName,
        callback: function ($$v) {
          _vm.activeName = $$v
        },
        expression: "activeName",
      },
    },
    [
      _c(
        "el-tab-pane",
        {
          attrs: {
            label: _vm.$t("cip.plat.wfOps.done.field.applicationInfo"),
            name: "first",
          },
        },
        [
          _c("el-card", { attrs: { shadow: "never" } }, [
            _c(
              "div",
              { ref: "printBody", staticClass: "wf-theme-default" },
              [
                _vm.option &&
                ((_vm.option.column && _vm.option.column.length > 0) ||
                  (_vm.option.group && _vm.option.group.length > 0))
                  ? _c("avue-form", {
                      ref: "form",
                      attrs: { option: _vm.option },
                      model: {
                        value: _vm.form,
                        callback: function ($$v) {
                          _vm.form = $$v
                        },
                        expression: "form",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-tab-pane",
        {
          attrs: {
            label: _vm.$t("cip.plat.wfOps.done.field.flowInfo"),
            name: "second",
          },
        },
        [
          _c(
            "el-card",
            {
              staticStyle: { "margin-top": "5px" },
              attrs: { shadow: "never" },
            },
            [_c("wf-flow", { attrs: { flow: _vm.flow } })],
            1
          ),
        ],
        1
      ),
      _c(
        "el-tab-pane",
        {
          attrs: {
            label: _vm.$t("cip.plat.wfOps.done.field.processTrace"),
            name: "third",
          },
        },
        [
          _vm.activeName == "third"
            ? [
                _c(
                  "el-card",
                  {
                    staticStyle: { "margin-top": "5px" },
                    attrs: { shadow: "never" },
                  },
                  [
                    _c("wf-design", {
                      ref: "bpmn",
                      staticStyle: { height: "500px" },
                      attrs: { options: _vm.bpmnOption },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }