<template>
  <el-tabs v-model="activeName" type="card">
    <el-tab-pane :label="$t('cip.plat.wfOps.done.field.applicationInfo')"
                 name="first">
      <el-card shadow="never">
        <div ref="printBody"
             class="wf-theme-default">
          <avue-form v-if="option && ((option.column && option.column.length > 0) || (option.group && option.group.length > 0))"
                     v-model="form"
                     ref="form"
                     :option="option">
          </avue-form>
        </div>
      </el-card>
    </el-tab-pane>
    <el-tab-pane :label="$t('cip.plat.wfOps.done.field.flowInfo')"
                 name="second">
      <el-card shadow="never"
               style="margin-top: 5px;">
        <wf-flow :flow="flow"></wf-flow>
      </el-card>
    </el-tab-pane>
    <el-tab-pane :label="$t('cip.plat.wfOps.done.field.processTrace')"
                 name="third">
      <template v-if="activeName == 'third'">
        <el-card shadow="never"
                 style="margin-top: 5px;">
          <wf-design ref="bpmn"
                     style="height: 500px;"
                     :options="bpmnOption"></wf-design>
        </el-card>
      </template>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import WfFlow from '../process/components/flow.vue'

import exForm from '../mixins/ex-form'
import theme from '../mixins/theme'

export default {
  mixins: [exForm, theme],
  components: { WfFlow },
  props: {
    processInstanceId: String,
    taskId: String
  },
  watch: {
    processInstanceId: {
      handler() {
        const { taskId, processInstanceId } = this
        if ((taskId && processInstanceId) || processInstanceId) this.getDetail(taskId, processInstanceId)
      },
      immediate: true
    }
  },
  data() {
    return {
      activeName: 'first',
      form: {},
      option: {},
      vars: [], // 需要提交的字段
      submitLoading: false, // 提交时按钮loading
    }
  },
  methods: {
    // 获取任务详情
    getDetail(taskId, processInsId) {
      this.getTaskDetail(taskId, processInsId).then(res => {
        const { process, form } = res
        const { variables } = process

        let { allForm } = form
        if (allForm) {
          const option = eval('(' + allForm + ')')
          option.menuBtn = false
          const { column, group } = option
          option.detail = true
          let event = ['change', 'blur', 'click', 'focus']
          if (column && column.length > 0) { // 处理column
            column.forEach(col => {
              if (col.type == 'dynamic') col.children.column.forEach(cc => {
                delete cc.value
                delete cc.event
                event.forEach(e => delete cc[e])
              })
              else {
                delete col.value
                delete col.event
                event.forEach(e => delete col[e])
              }
            })
          }

          if (group && group.length > 0) { // 处理group
            group.forEach(gro => {
              if (gro.column && gro.column.length > 0) {
                gro.column.forEach(col => {
                  if (col.type == 'dynamic') col.children.column.forEach(cc => {
                    delete cc.value
                    delete cc.event
                    event.forEach(e => delete cc[e])
                  })
                  else {
                    delete col.value
                    delete col.event
                    event.forEach(e => delete col[e])
                  }
                })
              }
            })
          }
          for (let key in variables) {
            if (!variables[key]) delete variables[key]
          }
          if (process.variables && process.variables.serialNumber) {
            column.unshift({
              label: this.$t('cip.plat.wfOps.done.field.SerialNumber'),
              prop: 'serialNumber',
              span: 24,
              detail: true,
            })
          }
          option.column = column
          option.group = group

          this.option = option
          this.form = variables
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 50px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px 10px 0;
}
::v-deep .bjs-powered-by {
  display: none;
}
</style>
